import React from 'react';
import {FormikErrors, FormikTouched} from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import i18n from "../../i18n";

export const contactFieldSchema = Yup.object().shape({
  email: Yup.string().email(i18n.t('Invalid_email')),
  phone_number: Yup.string().matches(/^[+]?[0-9]{8,12}$/, i18n.t('Invalid_phone_number')),
}).test('required', i18n.t('Field_is_required'), (value) => {
  return !!value?.email || !!value?.phone_number;
});
export type FormContactValues = Yup.InferType<typeof contactFieldSchema>;

export interface ContactFieldProps {
  name: string;
  error?: FormikErrors<FormContactValues>;
  touched?: FormikTouched<FormContactValues>;
  readOnly?: boolean;
  defaultValue?: FormContactValues;
  className?: string;
  onChange?: (field: string, value: string) => void;
  onFocus?: (field: string) => void;
}

function ContactField({name, error, touched, readOnly = false, defaultValue, className, onFocus, onChange}: ContactFieldProps) {
  return (

    <div
      className={className ?? clsx('form-control form-control-lg p-0 mb-4')}>
      <div className="d-flex">
        <input
          type={'email'}
          id={`id_${name}_email`}
          placeholder={'contact@example.com'}
          className={clsx(
            `form-control form-control-transparent`,
            {'is-invalid': touched?.email && error?.email},
            {'is-valid': touched?.email && !error?.email,}
          )}
          readOnly={readOnly}
          defaultValue={defaultValue?.email}
          onChange={(e) => {
            if(onChange) onChange('email', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('email')
          }}
        />
        <input
          type={'phone'}
          id={`id_${name}_phone_number`}
          placeholder={'670000000'}
          className={clsx(
            `form-control form-control-transparent`,
            {'is-invalid': touched?.phone_number && error?.phone_number},
            {'is-valid': touched?.phone_number && !error?.phone_number,}
          )}
          readOnly={readOnly}
          defaultValue={defaultValue?.phone_number}
          onChange={(e) => {
            if(onChange) onChange('phone_number', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('phone_number')
          }}
        />
      </div>
    </div>
  );
}

export default ContactField;
