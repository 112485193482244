import React from 'react';
import {FormikErrors, FormikTouched} from "formik";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import i18n from "../../i18n";

export const bankRIBSchema = Yup.object().shape({
  bank_code: Yup.string().matches(/^[0-9]{3,5}$/, i18n.t('Invalid_bank_code')).required(i18n.t('Bank_code_is_required')),
  agence_code: Yup.string().matches(/^[0-9]{5}$/, i18n.t('Invalid_agence_code')).required(i18n.t('Agence_code_is_required')),
  account_number: Yup.string().matches(/^[0-9]{10,20}$/, i18n.t('Invalid_account_number')).required(i18n.t('Account_number_is_required')),
  rib_key: Yup.string().matches(/^[0-9]{2,4}$/, i18n.t('Invalid_rib_key')).required(i18n.t('Rib_key_is_required')),
});
export type FormBankRIBValues = Yup.InferType<typeof bankRIBSchema>;

export interface BankRIBFieldProps {
  name: string;
  error?: FormikErrors<FormBankRIBValues>;
  touched?: FormikTouched<FormBankRIBValues>;
  defaultValue?: FormBankRIBValues;
  className?: string;
  onChange?: (field: string, value: string) => void;
  onFocus?: (field: string) => void;
}

function BankRIBField({name, error, touched, className, defaultValue, onFocus, onChange}: BankRIBFieldProps) {
  const {t} = useTranslation()
  return (
    <div
      className={className ?? clsx('form-control form-control-lg p-0 mb-4')}>
      <div className="d-flex">
        <input
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.bank_code`}
          placeholder={t('Bank Code')}
          className={clsx(
            `form-control form-control-transparent w-150px`,
            {'is-invalid': touched?.bank_code && error?.bank_code},
            {'is-valid': touched?.bank_code && !error?.bank_code,}
          )}
          style={{width: '20% !important;'}}
          id={`id_${name}_bank_code`}
          defaultValue={defaultValue?.bank_code}
          onChange={(e) => {
            if(onChange) onChange('bank_code', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('bank_code')
          }}
        />
        <input
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.agence_code`}
          placeholder={t('Agence Code')}
          className={clsx(
            `form-control form-control-transparent w-150px`,
            {'is-invalid': touched?.agence_code && error?.agence_code},
            {'is-valid': touched?.agence_code && !error?.agence_code,}
          )}
          id={`id_${name}_agence_code`}
          defaultValue={defaultValue?.agence_code}
          onChange={(e) => {
            if(onChange) onChange('agence_code', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('agence_code')
          }}
        />
        <input
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.account_number`}
          placeholder={t('Account Number')}
          className={clsx(
            `form-control form-control-transparent`,
            {'is-invalid': touched?.account_number && error?.account_number},
            {'is-valid': touched?.account_number && !error?.account_number,}
          )}
          id={`id_${name}_account_number`}
          defaultValue={defaultValue?.account_number}
          onChange={(e) => {
            if(onChange) onChange('account_number', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('account_number')
          }}
        />
        <input
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.rib_key`}
          placeholder={t('RIB Key')}
          className={clsx(
            `form-control form-control-transparent  w-70px`,
            {'is-invalid': touched?.rib_key && error?.rib_key},
            {'is-valid': touched?.rib_key && !error?.rib_key,}
          )}
          id={`id_${name}_rib_key`}
          defaultValue={defaultValue?.rib_key}
          onChange={(e) => {
            if(onChange) onChange('rib_key', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('rib_key')
          }}
        />
      </div>
    </div>
  );
}

export default BankRIBField;
