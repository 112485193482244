import React, {useState} from 'react';
import clsx from "clsx";
import {OtpInput as BaseOtpInput} from 'reactjs-otp-input';

export interface OtpInputProps {
  name: string;
  isSecure?: boolean;
  length?: number;
  inputType?: 'numeric' | 'text' | 'alphanumeric';
  onComplete?: (otp: string) => void;
  onChange?: (value: string) => void;
  value?: string;
}

function OtpInput({name, isSecure = false, length = 4, inputType = 'numeric', onChange, onComplete}: OtpInputProps) {
  const [value, setValue] = useState('')
  return (
    <BaseOtpInput
      numInputs={length}
      onChange={function (otp: string): void {
        setValue(otp);
        if (onChange) onChange(otp);
        if (otp.length == length && onComplete) {
          if (onComplete) onComplete(otp);
        }
      }}
      isInputNum={inputType === 'numeric'}
      isInputSecure={isSecure}
      inputStyle={clsx('form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2')}
      data-cy={`input-${name}`}
      value={value}
    />
  );
}

export default OtpInput;
