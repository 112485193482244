import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router'
import {useFormik} from 'formik'
import {useAppSelector} from "../../../store/hooks.ts";
import {selectConfig} from "../../../store/auth.ts";
import {useTranslation} from "react-i18next";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import i18n from "../../../../_mesomb/i18n";
import OtpInput from "../../../../_mesomb/partials/form/OtpInput.tsx";
import LoginOptions from "./LoginOptions.tsx";
import {Helmet} from "react-helmet";
import {AuthError, AuthProps} from "../../../../types";
import axios from "axios";
import * as Sentry from "@sentry/react";

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('Wrong email format'))
    .max(255, i18n.t('Maximum_characters', {max: 255}))
    .required(i18n.t('Field_is_required')),
  code: Yup.string()
    .matches(/^[a-zA-Z0-9]{6}$/, i18n.t('Invalid code'))
    .required(i18n.t('Field_is_required')),
})
type FormEmailValues = Yup.InferType<typeof formSchema>;

function Email({onLogin, configs}: AuthProps) {
  const {t} = useTranslation()
  const [step, setStep] = useState('email')
  const config = useAppSelector(selectConfig);
  const googleProvider =  config?.socialaccount?.providers?.find(p => p.id === 'google')

  const formik = useFormik<FormEmailValues>({
    initialValues: {
      email: '',
      code: '',
    },
    validationSchema: formSchema.omit(step == 'email' ? ['code'] : []),
    onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
      setSubmitting(true)
      if (step == 'email') {
        try {
          await onLogin('sendEmailCode', {email: values.email})
          setStatus(t('Code has been successfully sent! Please check your email.'))
          setStep('verify')
          setSubmitting(false)
        } catch (error) {
          setSubmitting(false)
          if (axios.isAxiosError(error) && error.response?.data?.errors) {
            setErrors((error.response.data.errors as AuthError[]).reduce((acc, item) => ({...acc, [item.param]: item.message}), {}))
          } else if (error instanceof Error) {
            if (error.message) {
              setStatus(error.message)
            }
            Sentry.captureException(error)
          }
        }
      } else {
        try {
          await onLogin('confirmEmailCode', values,);
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false)
          if (axios.isAxiosError(error) && error.response?.data?.errors) {
            setErrors((error.response.data.errors as AuthError[]).reduce((acc, item) => ({...acc, [item.param]: item.message}), {}))
          } else if (error instanceof Error) {
            if (error.message) {
              setStatus(error.message)
            }
            Sentry.captureException(error)
          }
        }
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_email_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-gray-900 fw-bolder mb-3'>{t('Sign In')}</h1>
          <div className='text-gray-500 fw-semibold fs-6'>{t('Welcome to MeSomb')}</div>
        </div>
        {/* begin::Heading */}

        {/* begin::Login options */}
        <LoginOptions configs={configs} onLogin={onLogin}/>
        {/* end::Login options */}

        {/* begin::Separator */}
        <div className='separator separator-content my-14'>
          <span className='w-200px text-gray-500 fw-semibold fs-7'>{t('Or with email')}</span>
        </div>
        {/* end::Separator */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-gray-900'>{t('Email')}</label>
          <input
            placeholder={t('Email address')}
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            // autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container form-text text-danger'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {step == 'verify' && (
          <>
            <label className='form-label fs-6 fw-bolder text-gray-900'>{t('Enter the code')}</label>
            <OtpInput
              name={'code'}
              onComplete={(otp) => formik.setFieldValue('code', otp)}
              isSecure={false}
              length={6}
              inputType={'alphanumeric'}
            />
            {formik.touched.code && formik.errors.code && (
              <div className='fv-plugins-message-container form-text text-danger'>
                <span role='alert'>{formik.errors.code}</span>
              </div>
            )}
          </>
        )}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <ButtonIndicator
            label={step == 'email' ? t('Send Code') : t('Confirm')}
            type={'submit'}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
          />
        </div>
        {/* end::Action */}

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          {t('Not a Member yet?')}{' '}
          <Link to='/auth/signup' className='link-primary'>
            {t('Sign up')}
          </Link>
        </div>
      </form>
      <Helmet>
        <title>{t('Email Authentication')}</title>
      </Helmet>
    </>
  )
}

export default Email;
