import React, {useMemo} from 'react';
import {FormikErrors, FormikTouched} from "formik";
import Select, {SingleValue} from "react-select";
import clsx from "clsx";
import {countryFlagUrl} from "../../utils";
import {useTranslation} from "react-i18next";
import countries from "../../utils/countries.ts";
import * as Yup from "yup";
import i18n from "../../i18n";

export const addressFieldSchema = Yup.object().shape({
  country: Yup.string().required(i18n.t('You_must_a_country')),
  address1: Yup.string(),
  address2: Yup.string(),
  town: Yup.string().required(i18n.t('Town_is_required')),
  zip: Yup.string(),
  region: Yup.string().required(i18n.t('Region_is_required')),
});
export type FormAddressValues = Yup.InferType<typeof addressFieldSchema>;

export interface AddressInputProps {
  name: string;
  error?: FormikErrors<FormAddressValues>;
  touched?: FormikTouched<FormAddressValues>;
  readOnly?: boolean;
  onChange?: (field: string, value: string) => void;
  onFocus?: (field: string) => void;
  defaultValue?: FormAddressValues;
  className?: string;
}

function AddressInput({name, error, touched, readOnly = false, onChange, onFocus, defaultValue, className}: AddressInputProps) {
  const {t} = useTranslation()
  const countryOptions = useMemo(() => {
    return Object.entries(countries).map(([key, value]) => {
      return {
        value: key,
        label: value.name,
        image: countryFlagUrl(key.toLowerCase())
      }
    })
  }, []);
  return (
    <div
      className={className ?? clsx('form-control form-control-lg p-0 mb-4')}>
      <Select
        id={`id_${name}_country`}
        className={clsx('react-select-styled react-select-transparent',
          {'is-invalid': touched?.country && error?.country},
          {'is-valid': touched?.country && !error?.country,}
        )}
        classNamePrefix='react-select'
        options={countryOptions}
        // options={options?.map(item => {
        //   item.label = (
        //     <div className='label'>
        //       {item.image ? <img src={item.image} alt='flag' className='w-20px rounded-circle me-2' /> : null}
        //       <span>{item.label}</span>
        //     </div>
        //   )
        //   return item;
        // })}
        formatOptionLabel={({label, image}) => (
          <div className='label'>
            {image ?
              <img src={image} alt='flag' className='w-20px rounded-circle me-2'/> : null}
            <span>{label}</span>
          </div>
        )}
        placeholder={t('Country')}
        defaultValue={countryOptions?.find(o => o.value === defaultValue?.country)}
        onChange={(e) => {
          if(onChange) onChange('country', (e as SingleValue<{ value: string }>)!.value)
        }}
        onFocus={() => {
          if(onFocus) onFocus('country')
        }}
        name={'id_address_country'}
        isDisabled={readOnly}
      />
      <input
        type={'text'}
        placeholder={t('Address line 1')}
        className={clsx(
          `form-control form-control-transparent`,
          {'is-invalid': touched?.address1 && error?.address1},
          {'is-valid': touched?.address1 && !error?.address1,}
        )}
        id={`id_${name}_address1`}
        readOnly={readOnly}
        maxLength={200}
        onChange={(e) => {
          if(onChange) onChange('address1', e.target.value)
        }}
        onFocus={() => {
          if(onFocus) onFocus('address1')
        }}
        defaultValue={defaultValue?.address1}
      />
      <input
        // id={`id_${name}`}
        type={'text'}
        placeholder={t('Address line 2')}
        className={clsx(
          `form-control form-control-transparent`,
          {'is-invalid': touched?.address2 && error?.address2},
          {'is-valid': touched?.address2 && !error?.address2,}
        )}
        id={`id_${name}_address2`}
        readOnly={readOnly}
        maxLength={200}
        onChange={(e) => {
          if(onChange) onChange('address2', e.target.value)
        }}
        onFocus={() => {
          if(onFocus) onFocus('address2')
        }}
        defaultValue={defaultValue?.address2}
      />
      <div className={'d-flex'}>
        <input
          // id={`id_${name}`}
          type={'text'}
          placeholder={t('City')}
          className={clsx(
            `form-control form-control-transparent`,
            {'is-invalid': touched?.town && error?.town},
            {'is-valid': touched?.town && !error?.town,}
          )}
          id={`id_${name}_town`}
          readOnly={readOnly}
          maxLength={200}
          onChange={(e) => {
            if(onChange) onChange('town', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('town')
          }}
          defaultValue={defaultValue?.town}
        />
        <input
          // id={`id_${name}`}
          type={'text'}
          placeholder={t('ZIP')}
          className={clsx(
            `form-control form-control-transparent`,
            {'is-invalid': touched?.zip && error?.zip},
            {'is-valid': touched?.zip && !error?.zip,}
          )}
          id={`id_${name}_zip`}
          readOnly={readOnly}
          maxLength={10}
          onChange={(e) => {
            if(onChange) onChange('zip', e.target.value)
          }}
          onFocus={() => {
            if(onFocus) onFocus('zip')
          }}
          defaultValue={defaultValue?.zip}
        />
      </div>
      <input
        // id={`id_${name}`}
        type={'text'}
        placeholder={t('State')}
        className={clsx(
          `form-control form-control-transparent`,
          {'is-invalid': touched?.region && error?.region},
          {'is-valid': touched?.region && !error?.region,}
        )}
        id={`id_${name}_region`}
        readOnly={readOnly}
        onChange={(e) => {
          if(onChange) onChange('region', e.target.value)
        }}
        onFocus={() => {
          if(onFocus) onFocus('region')
        }}
        defaultValue={defaultValue?.region}
      />
    </div>
  );
}

export default AddressInput;
