import React from 'react';
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {KTIcon} from "../../../../_mesomb/helpers";
import {useTranslation} from "react-i18next";
import {Link} from "react-router";
import {AuthProps} from "../../../../types";

function LoginOptions({onLogin}: Pick<AuthProps, 'onLogin'>) {
  const {t} = useTranslation()
  return (
    <div className='row g-3 mb-9'>
      {/* begin::Col */}
      <div className='col-md-6'>
        {/* begin::Google link */}
        <Link
          to={'/auth/login'}
          data-cy={'login-with-password'}
          className='btn btn-flex btn-sm btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <KTIcon iconName={'key'} className={'fs-2x'}/>
          {t('Login by Password')}
        </Link>
        {/* end::Google link */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6'>
        <GoogleLogin
          onSuccess={async credentialResponse => {
            await onLogin('google', {
              client_id: credentialResponse.clientId!,
              id_token: credentialResponse.credential!
            })
          }}
          // onError={() => {}}
          // shape={'pill'}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6'>
        {/* begin::Google link */}
        <Link
          to={'/auth/email'}
          data-cy={'login-with-email-code'}
          className='btn btn-flex btn-sm btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <KTIcon iconName={'sms'} className={'fs-2x'}/>
          {t('Login by Email')}
        </Link>
        {/* end::Google link */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6'>
        {/* begin::Google link */}
        <Link
          to={'/auth/phone'}
          data-cy={'login-with-phone-code'}
          className='btn btn-flex btn-sm btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100 login-with-phone'
        >
          <KTIcon iconName={'phone'} className={'fs-2x'}/>
          {t('Login by Phone')}
        </Link>
        {/* end::Google link */}
      </div>
      {/* end::Col */}
    </div>
  );
}

const LoginOptionsProvider = ({configs, onLogin}: AuthProps) => {
  const googleProvider =  configs?.socialaccount?.providers?.find(p => p.id === 'google')
  if (!googleProvider) {
    return <LoginOptions onLogin={onLogin}/>
  }

  return (
    <GoogleOAuthProvider clientId={googleProvider.client_id}>
      <LoginOptions onLogin={onLogin}/>
    </GoogleOAuthProvider>
  )
}

export default LoginOptionsProvider;
