import React, {useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router'
import {Form, Formik, useFormik} from 'formik'
import {useAppSelector} from "../../../store/hooks.ts";
import {selectConfig} from "../../../store/auth.ts";
import {useTranslation} from "react-i18next";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import i18n from "../../../../_mesomb/i18n";
import LoginOptions from "./LoginOptions.tsx";
import {Helmet} from "react-helmet";
import FormField from "../../../../_mesomb/partials/form/FormField.tsx";
import {AuthProps} from "../../../../types";

const phoneSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^[+]?[0-9]{11,12}$/, i18n.t('Invalid_phone_number'))
    .required(i18n.t('Field_is_required')),
  code: Yup.string()
    .matches(/^[a-zA-Z0-9]{6}$/, i18n.t('Invalid code'))
    .required(i18n.t('Field_is_required')),
})

interface IPhone {
  phone: string;
  code: string
}

const initialValues: IPhone = {
  phone: '',
  code: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function Phone({onLogin, configs}: AuthProps) {
  const {t} = useTranslation()
  const [step, setStep] = useState('phone')

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, {setStatus, setSubmitting, setErrors}) => {
          setSubmitting(true)
          if (step == 'phone') {
            onLogin('sendPhoneCode', {phone: values.phone}, setErrors).then(() => {
              setStatus(t('Code has been successfully sent! Please check your phone.'))
              setStep('verify')
              setSubmitting(false)
            }).catch(e => {
              setSubmitting(false)
            })
          } else {
            onLogin('confirmPhoneCode', values, setErrors).then(() => {
              setSubmitting(false)
            }).catch(e => {
              setSubmitting(false)
            })
          }
        }}
        validationSchema={phoneSchema.omit(step == 'phone' ? ['code'] : [])}>
        {({isSubmitting, isValid, touched, errors, values, status}) => {
          return (
            <Form noValidate className='form w-100' id='kt_phone_signin_form'>
              {/* begin::Heading */}
              <div className='text-center mb-11'>
                <h1 className='text-gray-900 fw-bolder mb-3'>{t('Sign In')}</h1>
                <div className='text-gray-500 fw-semibold fs-6'>{t('Welcome to MeSomb')}</div>
              </div>
              {/* begin::Heading */}

              {/* begin::Login options */}
              <LoginOptions configs={configs} onLogin={onLogin}/>
              {/* end::Login options */}

              {/* begin::Separator */}
              <div className='separator separator-content my-14'>
                <span className='w-200px text-gray-500 fw-semibold fs-7'>{t('Or with password')}</span>
              </div>
              {/* end::Separator */}

              {status ? (
                <div className='mb-lg-15 alert alert-success'>
                  <div className='alert-text font-weight-bold'>{status}</div>
                </div>
              ) : null}

              <FormField
                type={'phone'}
                name={'phone'}
                placeholder={t('Phone number')}
                label={t('Phone')}
                error={errors.phone}
                touched={touched.phone}
              />

              {step == 'verify' && (
                <FormField
                  type={'pin'}
                  name={'code'}
                  label={t('Enter the code')}
                  pinSize={6}
                  inputType={'numeric'}
                  error={errors.code}
                  touched={touched.code}
                />
              )}

              {/* begin::Action */}
              <div className='d-grid mb-10'>
                <ButtonIndicator
                  label={step == 'phone' ? t('Send Code') : t('Confirm')}
                  type={'submit'}
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                />
              </div>
              {/* end::Action */}

              <div className='text-gray-500 text-center fw-semibold fs-6'>
                {t('Not a Member yet?')}{' '}
                <Link to='/auth/signup' className='link-primary'>
                  {t('Sign up')}
                </Link>
              </div>
            </Form>
          )
        }}
      </Formik>
      <Helmet>
        <title>{t('Phone Authentication')}</title>
      </Helmet>
    </>
  )
}

export default Phone;
