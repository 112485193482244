import React, {lazy} from 'react';
import clsx from "clsx";
import MegaOptionField from "./MegaOptionField.tsx";
import {Field} from "formik";
import OtpInput from "./OtpInput.tsx";
import FormLabel from "./FormLabel.tsx";
import {countryFlagUrl} from "../../utils";
import FullNameField from "./FullNameField.tsx";
import {useTranslation} from "react-i18next";
import BankRIBField from "./BankRIBField.tsx";
import ContactField from "./ContactField.tsx";
import countries from "../../utils/countries.ts";
import PhoneField from "./PhoneField.tsx";
import {FormFieldProps} from "../../../types";
import * as Yup from "yup";
import CurrencyInput from "react-currency-input-field";
import {formatISO, parseISO} from "date-fns";
import I18n from "../../i18n";
import Flatpickr from "react-flatpickr";
import './FormField.css';
import {Form, InputGroup} from "react-bootstrap";

const DropzoneInput = lazy(() => import('./DropzoneInput.tsx'))
const AddressInput = lazy(() => import('./AddressInput.tsx'))
const QuillInput = lazy(() => import('./QuillInput.tsx'))
const SelectInput = lazy(() => import('./SelectInput.tsx'))
const TagsInput = lazy(() => import('./TagsInput.tsx'))


function FormField<T extends Yup.AnyObject>({pinSize = 4, inputType = 'text', error, type, name, label, help, helpPosition = 'label', placeholder, options, required, isSecure, showCountryCode, className, size='lg', marginBottom = 4, orientation = 'vertical', validate, remote, readOnly = false, prefix, onValueChanged, ...props}: FormFieldProps<T>) {
  const {t} = useTranslation()
  const key = String(name).replace(/\W/gm, '_')
  let inputMode = 'text';
  // let inputType = 'text';
  const fieldName = String(name);

  return (
    <Field name={fieldName}>
      {({
          field, // { name, value, onChange, onBlur }
          form: { touched: toucheds, errors, setFieldValue, setFieldTouched, initialValues, values },
          meta,
        }) => {
        const touched = toucheds[name];
        const error = errors[name];
        let input;
        let errorMessage = error ? <div className="form-text text-danger fs-7">{error}</div> : null;
        if (error && typeof error == 'object') {
          errorMessage = (
            <div className="form-text text-danger fs-7">{Object.values(error).map(value => <>{`${value}`}<br /></>)}</div>
          )
        }
        switch (type) {
          case 'tags':
            input = (
              <TagsInput
                name={fieldName}
                placeholder={placeholder}
                pattern={validate as unknown as RegExp}
                touched={touched}
                error={error}
                defaultValue={initialValues[name]}
                onChange={(value) => setFieldValue(name, value)}
              />
            )
            break;
          case 'select2':
          case 'country':
            if (type == 'country') {
              options = Object.entries(countries).map(([key, value]) => {
                return {
                  value: key,
                  label: value.name,
                  image: countryFlagUrl(key.toLowerCase())
                }
              });
            }
            input = (
              <SelectInput
                {...field}
                remote={remote}
                value={field.value}
                field={field}
                name={fieldName}
                placeholder={placeholder}
                options={options}
                size={size as "lg" | "sm" | "md"}
                onChange={(value) => setFieldValue(name, value)}
                {...props}
              />
            )
            break;
          case 'amount':
            input = (
              <CurrencyInput
                id={`id_${key}`}
                name={String(name)}
                placeholder="Please enter a number"
                // defaultValue={form.initialValues[name]}
                decimalsLimit={2}
                className={clsx(
                  `form-control form-control-${size}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && error,}
                )}
                onValueChange={(value, name) => {
                  setFieldValue(name, value);
                }}
                onBlur={field.onBlur}
                prefix={prefix}
              />
            );
            break;
          case 'megaOption':
            input = (
              <MegaOptionField<T>
                label={label}
                name={fieldName}
                options={options}
                touched={touched}
                error={error}
                readOnly={readOnly}
                className={clsx('row row-cols-1 g-5 row-cols-md-2', {'is-invalid': touched && error}, {'is-valid': touched && !error,})}
                itemClassName={props.itemClassName}
              />
            )
            break;
          case 'date':
            input = (
              <Flatpickr
                {...field}
                value={field.value ? parseISO(field.value) : undefined}
                onChange={([date]) => {
                  setFieldValue(name, formatISO(date).substring(0, 10))
                }}
                options={{
                  dateFormat: I18n.language == 'fr' ? 'd/m/Y' : 'Y-m-d',
                  static: true,
                  // mode: "range"
                }}
                className='form-control form-control-lg'
                placeholder={placeholder || (I18n.language == 'fr' ? 'jj/mm/aaaa' : 'yyyy-mm-dd')}
                id={`id_${key}`}
              />
            )
            break;
          case 'phone':
            input = (
              <PhoneField
                placeholder={placeholder}
                showCountryCode={showCountryCode}
                className={clsx('d-flex form-control p-0', {'is-invalid': touched && error},
                  {
                    'is-valid': touched && error,
                  })}
                name={key}
                defaultValue={field.initialValue ?? field.value}
                onChange={(value) => setFieldValue(name, value)}
              />
              // <PhoneField name={fieldName} placeholder={placeholder} touched={touched} errors={errors} showCountryCode={showCountryCode} />
            )
            break;
          case 'select':
            input = (
              <select
                {...field}
                className={clsx(
                  `form-select form-select-${size}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                id={`id_${key}`}
              >
                {options?.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            );
            break;
          case 'textarea':
            input = (
              <textarea
                {...field}
                id={`id_${key}`}
                placeholder={placeholder}
                className={clsx(
                  'form-control form-control-lg',
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                name={fieldName}
                rows={4}
              />
            )
            break;
          case 'dropzone':
            input = (
              <DropzoneInput
                title={placeholder}
                name={String(name)}
                onChange={(value) => setFieldValue(name, value)}
                file={props.file}
                accept={props.accept}
              />
            )
            break;
          case 'full_name':
            input = (
              <FullNameField
                name={String(name)}
                readOnly={readOnly}
                className={clsx(`form-control form-control-lg p-0 mb-${marginBottom}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                defaultValue={initialValues[name]}
                onChange={(field, value) => setFieldValue(`${fieldName}.${field}`, value)}
                onFocus={(field) => setFieldTouched(`${fieldName}.${field}`, true)}
              />
            )
            break;
          case 'address':
            input = (
              <AddressInput
                name={String(name)}
                error={error}
                touched={touched}
                readOnly={readOnly}
                onChange={(field, value) => setFieldValue(`${fieldName}.${field}`, value)}
                onFocus={(field) => setFieldTouched(`${fieldName}.${field}`, true)}
                className={clsx(`form-control form-control-lg p-0 mb-${marginBottom}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                defaultValue={initialValues[name]}
              />
            )
            break;
          case 'contact':
            input = (
              <ContactField
                error={error}
                touched={touched}
                readOnly={readOnly}
                className={clsx(`form-control form-control-lg p-0 mb-${marginBottom}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                defaultValue={initialValues[name]}
                name={String(name)}
                onChange={(field, value) => setFieldValue(`${fieldName}.${field}`, value)}
                onFocus={(field) => setFieldTouched(`${fieldName}.${field}`, true)}
              />
            )
            break;
          case 'bank_rib':
            input = (
              <BankRIBField
                name={fieldName}
                error={error}
                touched={touched}
                className={clsx(`form-control form-control-lg p-0 mb-${marginBottom}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                onChange={(field, value) => setFieldValue(`${fieldName}.${field}`, value)}
                onFocus={(field) => setFieldTouched(`${fieldName}.${field}`, true)}
              />
            )
            break;
          case 'quill':
            input = (
              <QuillInput
                {...field}
                id={`id_${fieldName}`}
                placeholder={placeholder}
                className={clsx(
                  'form-control form-control-transparent',
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                value={initialValues[name]}
                onChange={value => setFieldValue(name, value)}
                onFocus={() => setFieldTouched(name, true)}
                style={{height: 250}}
              />
            )
            break;
          case 'pin':
            input = (
              <OtpInput
                name={fieldName}
                value={values[name]}
                isSecure={isSecure}
                inputType={inputType}
                length={pinSize}
                onComplete={(otp) => setFieldValue(name, otp)}
                onChange={() => setFieldTouched(name, true)}
              />
            )
            break;
          case 'radio':
          case 'checkbox':
            if (options) {
              input = (
                <div className={orientation === 'horizontal' ? 'd-flex flex-wrap align-items-center text-gray-600 gap-5' : ''}
                     role="group" aria-labelledby="checkbox-group">
                  {options?.map((o, index) => (
                    <div key={o.value} className="form-check form-check-custom me-2 mb-2">
                      <input
                        {...field}
                        type={type}
                        name={fieldName}
                        value={o.value}
                        checked={field.value?.includes(o.value)}
                        className={'form-check-input'} id={`id_${key}_${index}`}
                      />
                      <label htmlFor={`id_${key}_${index}`}
                             className="form-check-label">{o.label}</label>
                    </div>
                  ))}
                </div>
              )
            } else {
              input = (
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    {...field}
                    type={type}
                    name={fieldName}
                    checked={field.value}
                    className={'form-check-input me-2'}
                    id={props.id || `id_${key}`}
                  />
                  <span className="d-flex flex-column">
                    {label && <FormLabel
                        name={key}
                        marginBottom={0} hideBadge={true} label={label} help={helpPosition == 'label' ? help : undefined} id={props.id}/>}
                    {placeholder && <span className="fs-7 text-muted">{placeholder}</span>}
                  </span>
                </div>
              )
            }
            break;
          case 'switch':
            input = (
              <div className="d-flex flex-stack">
                <div className="me-5">
                  {label && <label className="fs-6 fw-semibold">{label}</label>}
                  {help && <><br/><label className="fs-7 fw-semibold text-muted">{help}</label></>}
                </div>
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input
                    type='checkbox'
                    className='form-check-input'
                    {...field}
                    name={fieldName}
                    // value={value}
                    id={`id_${fieldName}`}
                    checked={initialValues[name]}
                  />
                  <label htmlFor={`id_${fieldName}`}
                         className="form-check-label fw-semibold text-muted">{field.value ? t('Yes') : t('No')}</label>
                </div>
              </div>
            )
            break;
          case 'color':
            input = (
              <InputGroup
                className={clsx(
                  `form-control form-control-${size}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                style={{padding: 0}}
              >
                <InputGroup.Text id="basic-addon1" style={{padding: 0, border: 0}}>
                  <Form.Control
                    type={'color'}
                    // placeholder="Username"
                    // aria-label="Username"
                    // aria-describedby="basic-addon1"
                    style={{
                      padding: 0,
                      border: 0,
                      borderTopRightRadius: 0,
                    }}
                    onChange={(value) => {
                      setFieldValue(fieldName, value.target.value)
                    }}
                    value={field.value}
                  />
                </InputGroup.Text>
                <Form.Control
                  style={{border: 0}}
                  value={field.value}
                  onChange={(value) => {
                    setFieldValue(fieldName, value.target.value)
                  }}
                  maxLength={7}
                  minLength={7}
                />
              </InputGroup>
            )
            break;
          default:
            if (type == 'phone') {
              inputMode = 'tel';
              inputType = 'tel';
            } else if (type == 'number') {
              inputMode = 'number';
            } else if (type == 'amount') {
              inputMode = 'number';
              inputType = 'number';
            } else if (type == 'hidden') {
              inputType = 'hidden';
            } else if (type == 'email') {
              inputType = 'email';
            } else if (type == 'file') {
              inputType = 'file';
            }
            input = (
              <input
                id={`id_${key}`}
                type={inputType}
                name={fieldName}
                placeholder={placeholder}
                className={clsx(
                  `form-control form-control-${size}`,
                  {'is-invalid': touched && error},
                  {'is-valid': touched && !error,}
                )}
                readOnly={readOnly}
                defaultValue={initialValues[name]}
                onChange={(e) => setFieldValue(name, e.target.value)}
                onFocus={field.onFocus}
                value={field.value}
                accept={props.accept}
              />
            )
            break;
        }
        return (
          <div className={clsx(`fv-row mb-${marginBottom}`, className)}>
            {(label && !(['checkbox', 'radio', 'switch'].includes(type) && !options)) && (
              <FormLabel name={key} label={label} help={helpPosition == 'label' ? help : undefined} required={required} />
            )}
            {input}
            {(helpPosition == 'bottom' && help) && <span className="form-text text-muted">{help}</span>}
            {errorMessage}
          </div>
        )
      }}
    </Field>
  )
}

export default FormField;
