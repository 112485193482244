import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {AuthConfig, AuthModel, UserModel} from "../../types";

export interface AuthSliceState {
  meta?: AuthModel
  user?: UserModel
  config?: AuthConfig
}

const initialState: AuthSliceState = {
  meta: undefined,
  user: undefined,
  config: undefined,
}

export const authSlice = createAppSlice({
  name: 'auth',
  initialState,
  reducers: create => ({
    setUser: create.reducer((state, action: PayloadAction<UserModel | undefined>) => {
      state.user = action.payload
    }),
    setAuth: create.reducer((state, action: PayloadAction<AuthModel | undefined>) => {
      state.meta = action.payload
    }),
    setConfig: create.reducer((state, action: PayloadAction<AuthConfig | undefined>) => {
      state.config = action.payload
    }),
    logout: create.reducer((state) => {
      state.meta = undefined;
      state.user = undefined;
    }),
  }),
  selectors: {
    selectUser: auth => auth.user,
    selectAuth: auth => auth.meta,
    selectConfig: auth => auth.config,
  },
});

export const { setUser, setAuth, setConfig, logout } =
  authSlice.actions

export const { selectUser, selectAuth, selectConfig } = authSlice.selectors
