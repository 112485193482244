import React from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import i18n from "../../i18n";

export const fullNameFieldSchema = Yup.object().shape({
  last_name: Yup.string().required(i18n.t('At least the last name is required')),
  first_name: Yup.string()
});
export type FullNameValues = Yup.InferType<typeof fullNameFieldSchema>;

export interface FullNameFieldProps {
  name: string;
  readOnly?: boolean;
  defaultValue: FullNameValues;
  onChange?: (field: string, value: string) => void;
  onFocus? (field: string): void;
  className?: string;
}

function FullNameField({name, readOnly = false, defaultValue, onChange, onFocus, className}: FullNameFieldProps) {
  const {t} = useTranslation()

  return (
    <div
      className={className ?? 'form-control form-control-lg p-0 mb-4'}>
      <div className="d-flex">
        <input
          id={`id_${name}_last_name`}
          type={'text'}
          name={`${name}.last_name`}
          placeholder={t('Last Name')}
          className={clsx(
            `form-control form-control-transparent`,
          )}
          defaultValue={defaultValue?.last_name}
          onChange={(e) => {
            if (onChange) onChange('last_name', e.target.value)
          }}
          // onBlur={field.onBlur}
          onFocus={() => {
            if (onFocus) onFocus(`last_name`)
          }}
          readOnly={readOnly}
        />
        <input
          id={`id_${name}_first_name`}
          type={'text'}
          name={`${name}.first_name`}
          placeholder={t('First Name')}
          className={clsx(
            `form-control form-control-transparent`,
          )}
          defaultValue={defaultValue?.first_name}
          onChange={(e) => {
            if (onChange) onChange('first_name', e.target.value)
          }}
          // onBlur={field.onBlur}
          onFocus={() => {
            if (onFocus) onFocus(`last_name`)
          }}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

export default FullNameField;
