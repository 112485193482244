import React from 'react';
import {Field} from "formik";
import {KTIcon} from "../../helpers";
import {FormFieldProps} from "../../../types";
import * as Yup from "yup";

function MegaOptionField<T extends Yup.AnyObject>({name, options, itemClassName, className, action, readOnly = false}: Omit<FormFieldProps<T>, 'type'>) {
  const key = String(name).replace(/\W/gm, '_')
  return (
    <div id={`id_${key}`} className={className}>
      {options!.map((o, index) => (
        <div className={itemClassName} key={`option-${index}`} data-cy={`mega-option-${o.value}`}>
          {/*<Field*/}
          {/*  type='radio'*/}
          {/*  className='btn-check'*/}
          {/*  name={name}*/}
          {/*  value={o.value}*/}
          {/*  id={`id_${name}_${index}`}*/}
          {/*  checked={o.value == value}*/}
          {/*/>*/}
          <Field
            type="radio"
            className={'btn-check'}
            name={name}
            value={o.value}
            id={`id_${String(name)}_${index}`}
            readOnly={readOnly}
            disabled={readOnly}
          />
          <label className={'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex align-items-center p-3'} htmlFor={`id_${key}_${index}`}>
            {o.image ? (<span className="svg-icon svg-icon-3x me-2">
              <img src={o.image} height="24" width="24" style={{borderRadius: 5}}/>
            </span>) : null}
            <span className="d-block fw-semibold text-start">
              <span className="text-dark fw-bold d-block fs-4">{o.label}</span>
              {o.description && <span className="text-muted fw-semibold fs-6">{o.description}</span>}
            </span>
          </label>
        </div>
      ))}
      {action && (
        <div className={itemClassName}>
          <label onClick={action.onClick}
                 className={'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex align-items-center p-3'}>
            {action.icon && <KTIcon iconName={String(action.icon)} className={'fs-2x'}/>}{' '}{action.title}
          </label>
        </div>
      )}
    </div>
  );
}

export default MegaOptionField;
