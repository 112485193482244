import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router'
import {useFormik} from 'formik'
import i18n from "../../../../_mesomb/i18n";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setAuthUrl} from "../core/AuthHelpers.ts";
import {Helmet} from "react-helmet";

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('Wrong email format'))
    .required(i18n.t('Field_is_required')),
})

export function ForgotPassword() {
  const {t} = useTranslation()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      axios.post(setAuthUrl('auth/password/request'), values).then(() => {
        setHasErrors(false)
        setSubmitting(false)
      }).catch(error => {
        setSubmitting(false)
        setStatus(t('The login detail is incorrect'))
      })
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-gray-900 fw-bolder mb-3'>{t('Forgot Password')} ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {t('Enter your email to reset your password.')}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {t('Sorry, looks like there are some errors detected, please try again.')}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{t('Reset_password_link_sent')}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{t('Email')}</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <ButtonIndicator
            type={'submit'}
            label={t('Submit')}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
            className='btn btn-primary me-4'
          />
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {t('Cancel')}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      <Helmet>
        <title>{t('Forget Password')}</title>
      </Helmet>
    </>
  )
}
