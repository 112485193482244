import React from 'react';
import {useTranslation} from "react-i18next";
import {KTIcon} from "../../helpers";
import clsx from "clsx";

export interface ButtonIndicatorProps {
  type: 'button' | 'submit' | 'reset';
  label: string;
  indicator?: string;
  loading?: boolean;
  width?: number;
  disabled?: boolean;
  marginTop?: number;
  onClick?: () => void;
  form?: string;
  icon?: string;
  'data-cy'?: string;
  className?: string;
}

function ButtonIndicator({type, label, indicator, loading = false, width = 100, disabled = false, marginTop = 5, icon, className = 'btn-primary', ...props} : ButtonIndicatorProps) {
  const {t} = useTranslation()
  return (
    <button
      type={type}
      className={clsx(`btn min-w-${width}px mt-${marginTop}`, className)}
      disabled={disabled}
      data-kt-indicator={loading ? 'on' : 'off'}
      {...props}
    >
       {!loading && (
        <span className="indicator-label">
          {label}
          {icon && <KTIcon iconName={icon} className='fs-3 ms-2 me-0'/>}
        </span>
      )}
      {loading && (
        <span className="indicator-progress" style={{display: 'block'}}>{indicator || t('Please wait')}...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
    </button>
  );
}

export default ButtonIndicator;
