import {CookieComponent} from "../../../../_mesomb/assets/ts/components";
import {setUser} from "../../../store/auth.ts";
import pkg from '../../../../../package.json' assert {type: 'json'}

export function setupAxios(axios: any, store?: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.baseURL = `${import.meta.env.VITE_APP_BACKEND_HOST}/api/v1.1/`
  axios.defaults.xsrfHeaderName = "X-CSRFToken"
  axios.defaults.xsrfCookieName = "csrftoken"
  // axios.defaults.headers['X-CSRFToken'] = CookieComponent.get('csrftoken')
  if (store?.getState().auth?.user?.locale) {
    axios.defaults.headers['Accept-Language'] = store.getState().auth?.user?.locale
  }
  axios.defaults.headers['X-MeSomb-Source'] = `Web/${pkg.version}`
  axios.interceptors.request.use(
    (config: {headers: {'X-Session-Token': string}, withCredentials: boolean}) => {
      config.withCredentials = true
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      config.headers['X-CSRFToken'] = CookieComponent.get('csrftoken')
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(function (response) {
    return response
  }, async function (error) {
    if (error.response?.data?.code === 'not_authenticated' && !location.pathname.startsWith('/auth')) {
      axios.delete(setAuthUrl('auth/session')).finally(() => {
        store.dispatch(setUser(undefined))
        window.location.href = '/auth'
      });
      return;
    }
    return Promise.reject(error)
  })
}

export function setAuthUrl(endpoint: string) {
  return `${import.meta.env.VITE_APP_BACKEND_HOST}/api/auth/browser/v1/${endpoint}`
}

// export {getAuth, saveAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, USER_LOCAL_STORAGE_KEY, saveUser, getUser, removeUser}
