import React from 'react';
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import i18n from "../../../../_mesomb/i18n";
import {useTranslation} from "react-i18next";
import {toAbsoluteStatic} from "../../../../_mesomb/helpers";
import OtpInput from "../../../../_mesomb/partials/form/OtpInput.tsx";
import axios from "axios";
import {AuthError} from "../../../../types";

const formSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[a-zA-Z0-9]{6}$/, i18n.t('Invalid code'))
    .required(i18n.t('Field_is_required')),
})
type FormVerifyEmailValues = Yup.InferType<typeof formSchema>;

function VerifyEmail({onSubmit}: {onSubmit: (code: string) => Promise<void>}) {
  const {t} = useTranslation()
  const formik = useFormik<FormVerifyEmailValues>({
    initialValues: {code: ''},
    validationSchema: formSchema,
    onSubmit: async (values, {setSubmitting, setErrors}) => {
      setSubmitting(true)
      try {
        await onSubmit(values.code);
      } catch (error) {
        setSubmitting(false)
        if (axios.isAxiosError(error) && error.response?.data?.errors) {
          const errors: Record<string, string> = (error.response.data.errors as AuthError[]).reduce((acc, item) => ({...acc, [item.param]: item.message}), {});
          if (errors.key) {
            errors.code = errors.key;
          }
          setErrors(errors)
        }
      }
    },
  })

  return (
    <form className="form w-100" id="kt_email_verification_form" onSubmit={formik.handleSubmit} noValidate>
      <div className="text-center mb-10">
        <img alt="Logo" className="mh-125px" src={toAbsoluteStatic('/static/images/svg/misc/smartphone-2.svg')}/>
      </div>

      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">{t('Email Verification')}</h1>
        <div
          className="text-muted fw-semibold fs-5 mb-5">{t('Check your mailbox and enter below the code you will receive by email (check also spams).')}</div>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      <div className='fv-row mb-3'>
        <label className='form-label fs-6 fw-bolder text-gray-900'>{t('Enter the code')}</label>
        <OtpInput
          name={'code'}
          onComplete={(otp) => formik.setFieldValue('code', otp)}
          isSecure={false}
          length={6}
          inputType={'alphanumeric'}
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container form-text text-danger'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>

      <div className='d-grid mb-10'>
        <ButtonIndicator
          label={t('Confirm')}
          type={'submit'}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting || !formik.isValid}
          data-cy={'submit-button-verify-email'}
        />
      </div>
    </form>
  );
}

export default VerifyEmail;
